import { omit } from "lodash-es";
import {
  AreaInputs,
  AreaNormalizedInputs,
  AreaStackedInputs,
  BarHorizontalInputs,
  BarHorizontalNormalizedInputs,
  BarHorizontalStackedInputs,
  BarInputs,
  BarNormalizedInputs,
  BarStackedInputs,
  DataType,
  Inputs,
  LineInputs,
  type Options,
  VisualizationType,
  dataTypeSchema,
} from "./schema";

import { VisualizationLegacyType as V1VisualizationType, VisualizationType as V2VisualizationType } from "..";

export const cleanOptions = (o: Options): Options => {
  return {
    ...omit(o || {}, ["series", "table"]),
    xAxis: omit(o?.xAxis || {}, ["categories", "type"]),
  };
};

export const isSupportedDataType = (dataType: string): dataType is DataType =>
  dataTypeSchema.safeParse(dataType).success;

const dateTypes: DataType[] = ["date", "timestamp_ntz", "timestamp_ltz"];
export const isDateType = (t: string) => {
  return dateTypes.includes(t as DataType);
};

export const isNumberType = (t: string) => {
  return t === "fixed" || t === "real";
};
const horizontalBar: VisualizationType[] = ["bar-horizontal", "bar-horizontal-stacked", "bar-horizontal-normalized"];
export const isBarHorizontal = (t: VisualizationType) => {
  return horizontalBar.includes(t);
};

const verticalBar: VisualizationType[] = ["bar", "bar-stacked", "bar-normalized"];
export const isBarVertical = (t: VisualizationType) => {
  return verticalBar.includes(t);
};

type Bar =
  | BarInputs
  | BarStackedInputs
  | BarNormalizedInputs
  | BarHorizontalInputs
  | BarHorizontalStackedInputs
  | BarHorizontalNormalizedInputs;
type Area = AreaInputs | AreaStackedInputs | AreaNormalizedInputs;

export const isBar = (i: Inputs): i is Bar => {
  return i.type.startsWith("bar") && i.type !== "bar-line";
};

export const isArea = (i: Inputs): i is Area => {
  return i.type.startsWith("area");
};

export const isLine = (i: Inputs): i is LineInputs => {
  return i.type === "line";
};

export const chartConstructorType = (i: Inputs): string => {
  return i.type === "candlestick" ? "stockChart" : "chart";
};

export const getV3TypeFromLegacyChartType = (
  chartType: V2VisualizationType | V1VisualizationType,
): VisualizationType => {
  if (chartType === "donut") return "pie" as VisualizationType;
  if (chartType === "bar2") return "bar" as VisualizationType;
  if (chartType === "line2") return "line" as VisualizationType;

  return chartType.replace("_", "-") as VisualizationType;
};
