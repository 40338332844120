const theme = {
  title: {
    align: "left",
    style: {
      fontSize: "1.5rem",
      fontWeight: "600",
    },
  },
  subtitle: {
    align: "left",
    useHTML: true,
    style: {
      fontSize: "1rem",
      paddingBottom: "18px",
    },
  },
  xAxis: {
    title: {
      margin: 14,
      style: {
        fontSize: "1rem",
        fontWeight: "600",
      },
    },
    labels: {
      style: {
        fontSize: "0.75rem",
        fontWeight: "300",
      },
    },
  },
  yAxis: {
    title: {
      margin: 18,
      style: {
        fontSize: "1rem",
        fontWeight: "600",
      },
    },
    labels: {
      style: {
        fontSize: "0.75rem",
        fontWeight: "300",
      },
    },
    gridLineWidth: 1.5,
  },
  legend: {
    verticalAlign: "top",
    align: "left",
    layout: "horizontal",
    itemStyle: {
      fontSize: "0.75rem",
    },
  },
  tooltip: {
    shared: true,
    pointFormat: '<span style="color:{series.color}">{series.name}: {point.y:,.3f}</span><br/>',
    headerFormat: "<b>{point.key}</b><br/>",
  },
  chart: {
    panKey: "shift",
    panning: {
      type: "xy",
      enabled: true,
    },
    zooming: {
      type: "xy",
    },
    style: {
      fontFamily: "Inter, sans-serif",
    },
  },
} satisfies Highcharts.Options;

export default theme;
